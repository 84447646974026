import { ActionTree } from "vuex";
import { LiveDataStateInterface } from "./state";
import { RootState } from "@/store/state";
import { livaDataMutations } from "./mutations";
import { fetchProgressiveJackpot, fetchLiveTransactions } from "@/network/LiveDataService/LiveData";

function removeArrayDuplicates(arr) {
	const uniqueItems = new Map();

	arr.forEach((item) => {
		const itemString = JSON.stringify(item);
		if (!uniqueItems.has(itemString)) {
			uniqueItems.set(itemString, item);
		}
	});

	return Array.from(uniqueItems.values());
}

export const actions: ActionTree<LiveDataStateInterface, RootState> = {
	async fetchProgressiveJackpot(context) {
		const res = await fetchProgressiveJackpot();
		if (res) context.commit(livaDataMutations.SET_PROGRESSIVE_JACKPOT, res);
	},
	updateProgressiveJackpot(context, payload) {
		context.commit(livaDataMutations.SET_PROGRESSIVE_JACKPOT_VALUE, payload.jackpot);
	},
	async fetchLiveTransactionList(context, payload) {
		if (payload.transactionType == "Deposit") {
			const currentList = context.rootGetters["liveData/getLiveDepositList"];
			const res = await fetchLiveTransactions(payload.transactionType);
			if (!res) return;
			if (currentList.length == 0) {
				const newList = [...res.filter((item) => item.status == 2), ...currentList].slice(0, 10);
				context.commit(livaDataMutations.SET_LIVE_DEPOSIT, newList);
			}
			if (currentList.length > 0) {
				const newList = removeArrayDuplicates([
					...res.filter((item) => item.status == 1),
					...currentList,
				]).slice(0, 10);
				context.commit(livaDataMutations.SET_LIVE_DEPOSIT, newList);
			}
			return res;
		}
		if (payload.transactionType == "Withdrawal") {
			const currentList = context.rootGetters["liveData/getLiveWithdrawalList"];
			const res = await fetchLiveTransactions(payload.transactionType);
			if (!res) return;
			if (currentList.length == 0) {
				const newList = [...res.filter((item) => item.status == 2), ...currentList].slice(0, 10);
				context.commit(livaDataMutations.SET_LIVE_WITHDRAWAL, newList);
			}
			if (currentList.length > 0) {
				const newList = removeArrayDuplicates([
					...res.filter((item) => item.status == 1),
					...currentList,
				]).slice(0, 10);
				context.commit(livaDataMutations.SET_LIVE_WITHDRAWAL, newList);
			}
			return res;
		}
	},
};
