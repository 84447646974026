<template>
	<img
		:src="imgUrl"
		:class="mode"
		:onerror="`this.setAttribute('url', '${imgUrl}');this.onerror=null;this.src='${imgDomain}VendorGameImage/DefaultImg.jpg'`"
		:decoding="decoding"
		:alt="alt"
	/>
</template>

<script>
export default {
	props: {
		imgSrc: {
			type: String,
			required: false,
			default: "",
		},
		mode: {
			type: String,
			required: false,
			default: "",
		},
		isFullUrl: {
			type: Boolean,
			required: false,
			default: false,
		},
		isGameImg: {
			type: Boolean,
			required: false,
			default: false,
		},
		decoding: {
			type: String,
			required: false,
			default: "auto",
		},
		alt: {
			type: String,
			required: false,
			default: "",
		},
	},
	computed: {
		imgDomain() {
			return process.env.VUE_APP_Media_Domain;
		},
		imgUrl() {
			var imgUrl = process.env.VUE_APP_Media_Domain;
			if (this.isGameImg == true) {
				imgUrl = process.env.VUE_APP_Media_CentralizedDomain_Domain;
			}
			if (this.imgSrc) {
				if (this.isFullUrl) {
					return this.imgSrc;
				} else {
					return imgUrl + this.imgSrc;
				}
			} else {
				return imgUrl + "VendorGameImage/DefaultImg.jpg";
			}
		},
	},
};
</script>

<style scoped lang="sass">
img
	width: 100%
	height: auto
</style>
