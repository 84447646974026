import httpClient from "./HttpClient";
import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { AutoDepositRequest } from "@/types/Payment/Request/AutoDepositRequest";
import { WithdrawalRequest } from "@/types/Payment/Request/WithdrawalRequest";
import { TransferRequest } from "@/types/Payment/Request/TransferRequest";
import { CompleteTransferRequest } from "@/types/Payment/Request/CompleteTransferRequest";
import { TransferAllAmountRequest } from "@/types/Payment/Request/TransferAllAmountRequest";
import { TransferAllBalToMainRequest } from "@/types/Payment/Request/TransferAllBalToMainRequest";
import { SetTicketToRejectedRequest } from "@/types/Payment/Request/SetTicketToRejectedRequest";
import { DepositResultResponse } from "@/types/Payment/Response/DepositResultResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";
import { BonusBirthdayResponse } from "@/types/Payment/Response/BonusBirthdayResponse";
import { TransferResultDto } from "@/types/Payment/Response/TransferResultResponse";
import { CompleteTransferResponse } from "@/types/Payment/Response/CompleteTransferResponse";
import { TransferAllBalResponse } from "@/types/Payment/Response/TransferAllBalResponse";
import { TransferAllAmountResponse } from "@/types/Payment/Response/TransferAllAmountResponse";
import { GetUserDepositAmountResponse } from "@/types/Payment/Response/GetUserDepositAmountResponse";
import { GetUserDepositAmountRequest } from "@/types/Payment/Request/GetUserDepositAmountRequest";
import { GetVendorsBalanceAndTransferRequest } from "@/types/Payment/Request/GetVendorsBalanceAndTransferRequest";
import { GetVendorsBalanceAndTransferResponse } from "@/types/Payment/Response/GetVendorsBalanceAndTransferResponse";
import { encrypt } from "@/composables/useEncryption";

const addDeposit = (
	loginId: string,
	amount: number,
	to: string,
	ipAddress: string,
	bonusId: number
): Promise<DepositResultResponse> => {
	const endPoint = `/api/Payment/AddDepositTicket`;
	const request: AutoDepositRequest = {
		loginID: loginId,
		amount: amount,
		to: to,
		method: "AutoDeposit",
		reference: null,
		needNoms: true,
		ipAddress: ipAddress,
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const addManualDeposit = (
	loginId: string,
	amount: number,
	to: string,
	channel: string,
	receipt: File,
	ipAddress: string,
	eventId: number,
	eventRewardId: number,
	bonusID: number
): Promise<DepositResultResponse> => {
	Object.assign(httpClient.defaults, { headers: { "Content-Type": "multipart/form-data" } });
	const formData = new FormData();
	// formData.append("LoginID", loginId);
	// formData.append("Amount", amount.toString());
	// formData.append("To", to);
	// formData.append("IpAddress", ipAddress);
	// formData.append("Channel", channel);
	// formData.append("EventId", eventId.toString());
	// formData.append("EventRewardId", eventRewardId.toString());
	// formData.append("BonusId", bonusID.toString());
	formData.append(
		"hash",
		encrypt({
			LoginID: loginId,
			Amount: amount,
			To: to,
			BonusID: bonusID,
			EventID: eventId,
			EventRewardID: eventRewardId,
			Channel: channel,
			IpAddress: ipAddress,
		})
	);
	formData.append("Receipt", receipt);
	const endPoint = `/api/Payment/AddManualDepositTicket`;
	const result = httpClient.post(endPoint, formData).then((response) => response.data);
	Object.assign(httpClient.defaults, { headers: { "Content-Type": "application/json" } });
	return result;
};

const addWithdrawal = (
	loginId: string,
	amount: number,
	to: string,
	accountNumber: string,
	accountHolder: string,
	ipAddress: string
): Promise<boolean> => {
	const endPoint = `/api/Payment/AddWithdrawalTicket`;
	const request: WithdrawalRequest = {
		loginID: loginId,
		amount: amount,
		from: "Internal",
		to: to,
		accountHolder: accountHolder,
		accountNumber: accountNumber,
		ipAddress: ipAddress,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const addTransfer = (
	loginID: string,
	vendorFrom: string,
	vendorTo: string,
	amount: number,
	bonusID: number,
	ipAddress: string
): Promise<TransferResultDto> => {
	const endPoint = `/api/Payment/AddTransferTicket`;
	const request: TransferRequest = {
		loginID: loginID,
		vendorFrom: vendorFrom.toString(),
		vendorTo: vendorTo.toString(),
		amount: amount,
		bonusID: bonusID,
		ipAddress: ipAddress,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const completeTransfer = (ticketId: number, bonusId: number): Promise<CompleteTransferResponse> => {
	const endPoint = `/api/Payment/CompleteTransferTicket`;
	const request: CompleteTransferRequest = {
		ticketId: ticketId,
		bonusId: bonusId,
	};

	return httpClient.put(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getStatement = (
	loginId: string,
	type: string,
	from?: Date | string,
	to?: Date | string,
	maxAmount?: number
): Promise<StatementResponse[]> => {
	const endPoint = `/api/Payment/GetMemberTicketList`;
	const params = {
		loginId: loginId,
		type: type,
		from: from,
		to: to,
		maxAmount: maxAmount,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getTransferStatement = (loginId: string, from?: Date, to?: Date): Promise<StatementResponse[]> => {
	const endPoint = `/api/Payment/GetTransferTicketList`;
	const params = {
		loginId: loginId,
		from: from,
		to: to,
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getBonusReferral = (id: string): Promise<BonusReferralResponse[]> => {
	const endPoint = `/api/Bonus/Referral/${id}`;
	const params = {
		id: id,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getBonusBirthday = (id: string): Promise<BonusBirthdayResponse> => {
	const endPoint = `/api/Bonus/Birthday/${id}`;
	const params = {
		id: id,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};
const transferAllAmount = (
	loginID: string,
	vendorFrom: string,
	vendorTo: string
): Promise<TransferAllAmountResponse> => {
	const endPoint = `/api/Payment/TransferAllAmount`;
	const request: TransferAllAmountRequest = {
		loginID: loginID,
		vendorFrom: vendorFrom,
		vendorTo: vendorTo,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const transferAllBalToMain = (loginID: string): Promise<TransferAllBalResponse> => {
	const endPoint = `/api/Payment/TransferAllBalToMain`;
	const request: TransferAllBalToMainRequest = {
		loginID: loginID,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getVendorsBalanceAndTransfer = (
	loginID: string,
	vendorTo: string,
	ipAddress: string
): Promise<GetVendorsBalanceAndTransferResponse> => {
	const endPoint = `/api/Payment/GetVendorsBalanceAndTransfer`;
	const request: GetVendorsBalanceAndTransferRequest = {
		loginID: loginID,
		vendorTo: vendorTo,
		ipAddress: ipAddress,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const setTicketToRejected = (ticketId: number): Promise<boolean> => {
	const endPoint = `/api/Payment/SetTicketToRejected`;
	const request: SetTicketToRejectedRequest = {
		id: ticketId,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getUserDepositAmount = (
	userIds: string[],
	dateFrom: Date,
	dateTo: Date
): Promise<GetUserDepositAmountResponse[]> => {
	const endPoint = `/api/Payment/GetUserDepositAmount`;
	const request: GetUserDepositAmountRequest = {
		userIds: userIds,
		dateFrom: dateFrom,
		dateTo: dateTo,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

export default {
	getStatement,
	addDeposit,
	addManualDeposit,
	addWithdrawal,
	addTransfer,
	completeTransfer,
	getBonusReferral,
	getBonusBirthday,
	getTransferStatement,
	transferAllAmount,
	transferAllBalToMain,
	setTicketToRejected,
	getUserDepositAmount,
	getVendorsBalanceAndTransfer,
};
